#home{
    position: relative;
    background: url("../../assets/bgIMG.png");
    background-size: cover; //whenever you use img as cover
    background-repeat: repeat;
    background-position: center;

    .app__wrapper{
        padding: 0;

        .copyright{
            display: none;
        }
    }
}

.app__header{
    flex: 1;
    width: 100%;
    height: 100%; //main section to take entirty of page
    flex-direction: row;

    padding: 6rem 2rem 0rem;


    @media screen and (min-width: 2000px) {
        padding-top: 8rem; //for large devices
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;  //when mobile they go in row
    }
    @media screen and (max-width: 400px) {
        padding-top: 6rem 1rem 2rem;
    }
}

.app__header-info{
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    margin: 0 2rem;

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp , .tag-cmp{
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 1rem;

        p{
            width: 100%;
            // text-transform: uppercase;
            text-align: right;
        }        
    }

    span{
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }
    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: start;
    }
}

.app__header-circles{
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;

    margin-left: 1rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;


        background: var(--white-color);
        box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

        img{
            width: 60%;
            height: 60%;
        }
    }

    //first child
    div:nth-child(1){
        width: 100px;
        height: 100px;
    }

    div:nth-child(2){
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    //Larger devices
    @media screen and (min-width: 2000px) {
        div:nth-child(2){
            width: 400px;
            height: 400px;
        }
    
        div:nth-child(3){
            width: 170px;
            height: 170px;
        }
        div:nth-child(1){
            width: 200px;
            height: 200px;
        }
    }
    //smaller devices
    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div{
            margin: 1rem;
        }
    }
}

.app__header-img{
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img{
        width: 100%;
        border-radius: 50%;
        object-fit: contain;
        z-index: 1;
        filter: grayscale(1); 
    }
    .overlay_circle{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 0; //photo will come to front of a circle
        width: 100%;
        height: 90%;

        //for mobile
        @media screen and (max-width: 1200px) {
            margin: 2rem 0;
        }
    }
}

